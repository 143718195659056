import { createTheme } from "@mui/material";
import breakpoints from "./breakpoints";
import typography from "./typography";
import palette from "./palette";

const theme = createTheme({
  breakpoints,
  typography,
  palette,
  shape: {
    borderRadius: 8
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width: 960px)": {
        minHeight: 64
      }
    }
  },
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 2
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "1rem",
          "&:hover": {
            backgroundColor: "transparent"
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.75)"
        }
      }
    }
  }
});

export default theme;
