import { type ShortFormValue } from "@schemas/shortForm";

export interface IItem {
  cmsId: number;
  type: ITEMTYPE;
  name: string;
  title: string;
  code: string;
  imageUrl: string;
  vendor: IVendor;
  locationText: string;
  locationLink: string;
  website: string;
  description: string;
  highlight: string;
  price: string | null;
  isFlashSale: boolean;
  isVisibleOnListing: boolean;
  showRedemptionPeriod?: boolean;
  cta: string | null;
  redeemType: REDEEMTYPE;
  redemptionFrom: string;
  redemptionTo: string;
  scheduleFrom: string;
  scheduleTo: string;
  couponValidity: number;
  showTimer: boolean;
  codeNotRequired: boolean;
  displayQRCode: boolean;
  offerCode: string;
  termsLink: string;
  hasStarted?: boolean;
  hasExpired?: boolean;
  categories: string[];
  enableGwe: boolean;
  requireCouponVerification: boolean;
  requireThirdPartyIntegration: boolean;
  typeOfIntegration: INTEGRATION_TYPE;
  thankYouMessage: string;
  confirmationMessage: string;
  videoUrl: string;
  secondaryBtnText: string;
  secondaryBtnCtaUrl: string;
  exploreLatestPromotion: boolean;
  leadFormTitle: string;
  ctaButtonText: string;
  formConfig: FormConfig | null;
}

export interface FormConfig {
  title: string;
  desc: string;
  formFields: FormField[];
  thankyouConfig: {
    title: string;
    desc: string;
  };
}

export interface FormField {
  key: string;
  title: string;
  isRequired: boolean;
  charLimit: number | string | null;
  other: boolean;
  options: string[];
  type:
    | 'name'
    | 'mobile'
    | 'email'
    | 'accountSmartcard'
    | 'identificationNo'
    | 'dropdown'
    | 'openEnded'
    | 'multiselect'
    | 'promoCode';
}

export interface IDropdown {
  Options: string[];
  Title: string;
}

export interface ICategory {
  code: string;
  name: string;
  enabled: boolean;
}

export enum ITEMTYPE {
  all = "all",
  reward = "reward",
  promotion = "promotion",
}

export enum LANGUAGE {
  bm = "may",
  en = "eng",
}

export enum LANGUAGES {
  may = "bm",
  eng = "en",
}

export enum INTEGRATION_TYPE {
  direct = "direct",
  iqiyi = "iqiyi",
}

export enum REDEEMTYPE {
  online = 1,
  offline,
}

interface IVendor {
  cmsId: number;
  name: string;
  phone: string;
  logo: string;
  email: string;
}

export type SortOrder = "asc" | "desc";

export interface ISortOption {
  sortBy: string;
  sortOrder: SortOrder;
}

export enum ERRORTYPE {
  notFound = 1,
  validation,
  error,
}

export interface IErrorModalState {
  open: boolean;
  title: string;
  message: string;
}

export class ErrorModalState implements IErrorModalState {
  open = false;
  title = "";
  message = "";
}

export interface ShortFormPostParams {
  [key: string]: NonNullable<ShortFormValue> | boolean;
  captcha: boolean;
  promoCampaign: string;
}
