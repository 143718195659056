import React from "react";
import { LiveChat } from "acm-components";
import { useLocation } from "react-router-dom";

import type { LiveChatConfig } from "acm-components";

interface ILiveChatWidgetProps {
  config: LiveChatConfig;
}

const LiveChatWidget = ({ config }: ILiveChatWidgetProps) => {
  const { pathname } = useLocation();
  return <LiveChat config={config} path={pathname} />;
};

export default LiveChatWidget;
