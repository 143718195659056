import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";

import configReducer from "@ducks/config";
import couponReducer from "@ducks/coupon";
import listingReducer from "@ducks/listing";
import profileReducer from "@ducks/profile";
import sideContentListingReducer from "@ducks/sideContentListing";
import categoriesReducer from "@ducks/categories";

const rootReducer = combineReducers({
  config: configReducer,
  coupon: couponReducer,
  listing: listingReducer,
  profile: profileReducer,
  sideContent: sideContentListingReducer,
  categories:categoriesReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middlewares = [thunk];
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middlewareEnhancer = composeEnhancers(applyMiddleware(...middlewares));

  return createStore(rootReducer, middlewareEnhancer);
}
