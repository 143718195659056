import React, { FunctionComponent } from "react";
import { ContainerProps, Container as MuiContainer } from "@mui/material";

export type IContainerProps = ContainerProps & StyleProps;

interface StyleProps {
  fullWidthMobile?: boolean;
  maxWidth?: string | number;
  id?: string;
}

const Container: FunctionComponent<IContainerProps> = props => {
  const { fullWidthMobile, maxWidth, ...containerProps } = props;

  return (
    <MuiContainer
      sx={({ breakpoints }) => ({
        "&.MuiContainer-root": {
          position: "relative",
          maxWidth: maxWidth !== undefined ? maxWidth : undefined,
          [breakpoints.down("md")]: {
            px: fullWidthMobile ? 0 : 2
          }
        }
      })}
      {...containerProps}
    />
  );
};

Container.displayName = "Container";

Container.defaultProps = {
  fullWidthMobile: false
};

export default Container;
