export interface IFortressResponse<T> {
  responseCode: number;
  responseMessage: string;
  response: {
    token: string;
    config: IConfig<T>[];
  };
}

interface IConfig<T> {
  key: string;
  name: string;
  value: T;
  schema_id?: any;
  editable: boolean;
}

export function extractConfig<T>(
  configKey: string,
  data: IFortressResponse<T>
) {
  const configs = data.response.config;
  const config = configs.find(({ key }) => key === configKey);
  return config !== undefined ? config.value : null;
}
