import isEmpty from "lodash.isempty";
import { IULMProfile, Status } from "@ducks/profile/types";
import { cookieGet, cookieSet } from "@lib/storage";
import { ULM_ACCESS_TOKEN_COOKIE_NAME } from "@constants/storage";
import "array-flat-polyfill";

export const extractSmartCard = (ulmProfile: IULMProfile): string[] | [] => {
  try {
    return (
      ulmProfile.astro_entitlements.accounts
        // Traverse profiles with multiple accounts and filter active accounts
        .filter(
          account =>
            account.subscriptions.length > 0 &&
            account.status &&
            account.status.toUpperCase() === Status.active
        )
        // Traverse in each account and filter active subscription
        .map(account =>
          account.subscriptions.filter(
            subscription =>
              subscription.status &&
              subscription.status.toUpperCase() === Status.active
          )
        )
        // Flatten array to 1-D
        .flat(Infinity)
        // Extract only smartcard
        .map((subscription:any) => subscription.subscriptionNumber)
        .sort((a, b) => {
          return b - a;
        })
    );
  } catch (error) {
    return [];
  }
};

export const isULMSessionActive = (): boolean => {
  const authToken = cookieGet(ULM_ACCESS_TOKEN_COOKIE_NAME);
  const expired = isEmpty(authToken);
  return !expired;
};

export const getJWTCookieName = (smartcard: string): string => {
  return `rewards_token_${smartcard.substr(smartcard.length - 4)}`;
};

export const setJWTSessionToken = (
  smartcard: string,
  sessionToken: string,
  duration: number
): void => {
  cookieSet(getJWTCookieName(smartcard), sessionToken, duration);
};

export const getJWTSessionToken = (smartcard: string): string | null => {
  return cookieGet(getJWTCookieName(smartcard));
};

export const isJWTSessionActive = (smartcard: string): boolean => {
  const sessionToken = getJWTSessionToken(smartcard);
  const expired = isEmpty(sessionToken);
  return !expired;
};
