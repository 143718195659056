import { TypographyVariantsOptions } from "@mui/material";

/**
 * Line Height rule = Font Size + 6px
 * +-------+-----------+-------------+
 * | Type  | Font Size | Line Height |
 * +-------+-----------+-------------+
 * | h1    | 28px      | 34px        |
 * | h2    | 24px      | 30px        |
 * | h3    | 20px      | 26px        |
 * | h4    | 16px      | 22px        |
 * | body1 | 16px      | 22px        |
 * | body2 | 14px      | 20px        |
 * +-------+-----------+-------------+
 */

const typography: TypographyVariantsOptions = {
  htmlFontSize: 16,
  fontFamily: [
    "Mulish",
    "-apple-system",
    "BlinkMacSystemFont",
    "'Segoe UI'",
    "Roboto",
    "'Helvetica Neue'",
    "Arial",
    "Microsoft YaHei",
    "微软雅黑",
    "STXihei",
    "华文细黑",
    "sans-serif",
    "'Apple Color Emoji'",
    "'Segoe UI Emoji'",
    "'Segoe UI Symbol'"
  ].join(","),
  fontSize: 16,
  h1: {
    fontWeight: 800,
    fontSize: "1.75rem",
    lineHeight: "2rem"
  },
  h2: {
    fontWeight: 800,
    fontSize: "1.5rem",
    lineHeight: "1.875rem"
  },
  h3: {
    fontWeight: 800,
    fontSize: "1.25rem",
    lineHeight: "1.625rem"
  },
  h4: {
    fontWeight: 800,
    fontSize: "1rem",
    lineHeight: "1.375rem"
  },
  body1: {
    fontSize: "1rem",
    lineHeight: "1.375rem"
  },
  body2: {
    fontSize: "0.875rem",
    lineHeight: "1.25rem"
  },
  button: {
    fontSize: "1rem",
    lineHeight: 1,
    fontWeight: "bold",
    textTransform: "none"
  }
};

export default typography;
