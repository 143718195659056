// Session storage
export const SESSION_SELECTED_SORT = "ACM_REWARDS_SORT";

// Local storage
export const LOCAL_ID_TYPE = "ACM_REWARDS_ID_TYPE";
export const LOCAL_ACCOUNT_NUMBER = "ACM_REWARDS_ACCOUNT_NUMBER";
export const LOCAL_JWT_TOKEN = "ACM_REWARDS_JWT_TOKEN";
export const LOCAL_JWT_TOKEN_EXPIRY = "ACM_REWARDS_JWT_EXPIRY";

export const ULM_ACCESS_TOKEN_COOKIE_NAME = "acm_ulm_access_token";
