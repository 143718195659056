import ErrorMessage from "@components/ErrorMessage";
import { ILanguage } from "@ducks/config/types";
import { AppState } from "@store";
import React, { ErrorInfo, ReactNode } from "react";
import { connect } from "react-redux";

interface IStateProps {
  language?: ILanguage;
}
interface Props extends IStateProps {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorHandler extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    const { language } = this.props;

    if (this.state.hasError && language) {
      return (
        <ErrorMessage
          heading={language.GENERIC_ERROR_TITLE}
          subHeading={language.GENERIC_ERROR_MESSAGE_2}
          buttonText={language.BACK_TO_HOME}
        />
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  language: state.config.language
});

export default connect(mapStateToProps, null)(ErrorHandler);
