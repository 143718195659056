import { SvgIconProps, SvgIcon, SxProps, Theme } from "@mui/material";
import React, { FunctionComponent } from "react";

export interface ISvgIconProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
  borderFill?: string;
}

export const CloseIcon: FunctionComponent<ISvgIconProps> = (props) => {
  const { fill, style, ...svgProps } = props;
  const styleProps: React.CSSProperties = {
    transform: "scale(1.25)",
    ...style,
  };
  return (
    <svg
      {...svgProps}
      style={styleProps}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
    >
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path fill="none" d="M-4-4h24v24H-4V-4z" />
      <path
        fill={fill}
        // eslint-disable-next-line max-len
        d="M13.2 12c.1.2.2.3.2.5s-.1.4-.2.6c-.3.3-.8.3-1.1 0l-4-4-4 4c-.2.2-.4.2-.6.2-.2 0-.4-.1-.6-.2-.3-.3-.3-.8 0-1.1l4-4-4-4c-.2-.1-.3-.3-.3-.5s.1-.4.2-.6l.1-.1c.3-.2.7-.2 1 .1l4 4 4-4c.2-.2.4-.2.6-.2.2 0 .4.1.6.2.3.3.3.8 0 1.1l-4 4 4.1 4z"
      />
    </svg>
  );
};

export const PlaceholderIcon: FunctionComponent<any> = (props) => {
  const { fill = "#d8d8d8", ...svgProps } = props;
  return (
    <svg
      {...svgProps}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 51"
      width="64"
      height="51"
    >
      <g>
        <path
          fill={fill}
          // eslint-disable-next-line max-len
          d="M64 5.31v40.379a5.343 5.343 0 01-5.335 5.31H5.331a5.145 5.145 0 01-3.767-1.562A5.093 5.093 0 010 45.688V5.31A5.33 5.33 0 015.331 0h53.334A5.337 5.337 0 0164 5.31zm-5.335-1.06H5.331a1.027 1.027 0 00-.75.316 1.016 1.016 0 00-.314.744v40.379a1.016 1.016 0 00.314.744 1.027 1.027 0 00.75.316h53.334a1.082 1.082 0 001.068-1.06V5.31a1.082 1.082 0 00-1.068-1.06zm-39.2 6.11a6.374 6.374 0 01-4.532 10.889 6.2 6.2 0 01-4.536-1.859 6.4 6.4 0 019.068-9.029zm36 17.263v14.875H8.531v-6.374l10.67-10.625 5.331 5.314 17.069-17z"
        />
      </g>
    </svg>
  );
};

export const CaretIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="M8.718 10.245a1.015 1.015 0 01-1.436 0l-2.99-3a1 1 0 01.718-1.7h5.98a1 1 0 01.717 1.7z"
    />
  </SvgIcon>
);

export const SelectedIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3.5 8.967l2.615 3 6.343-7.466"
    />
  </SvgIcon>
);

interface CheckboxIconProps extends SvgIconProps {
  fillColor?: string;
  borderColor?: string;
  checked: boolean;
  tickColor?: string;
}

export const CheckboxIconSvg: React.FC<CheckboxIconProps> = ({
  fillColor = "transparent",
  borderColor = "#d8d8d8",
  checked = false,
  tickColor = "#0B0E17",
}) => (
  <SvgIcon>
    <g fill={fillColor} stroke={borderColor} transform="translate(2 2)">
      <rect width="20" height="20" rx="4" stroke="none" />
      <rect x=".1" y=".1" width="19" height="19" rx="3.5" fill="none" />
    </g>
    {checked && (
      <path
        d="M7.522 12.731l2.615 3 6.343-7.466"
        fill="none"
        stroke={tickColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    )}
  </SvgIcon>
);

export const SpinnerIcon: React.FC<SvgIconProps> = ({
  sx: sxProp,
  ...props
}) => (
  <SvgIcon
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    {...props}
    sx={
      [
        { display: "block", m: "auto", background: "0% 0%" },
        sxProp,
      ].flat() as SxProps<Theme>
    }
  >
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.9166666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
      transform="rotate(30 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.8333333333333334s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
      transform="rotate(60 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.75s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
      transform="rotate(90 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.6666666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
      transform="rotate(120 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.5833333333333334s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
      transform="rotate(150 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.5s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
      transform="rotate(180 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.4166666666666667s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
      transform="rotate(210 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.3333333333333333s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
      transform="rotate(240 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.25s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
      transform="rotate(270 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.16666666666666666s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
      transform="rotate(300 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="-0.08333333333333333s"
        repeatCount="indefinite"
      />
    </rect>
    <rect
      x="46"
      y="2"
      rx="4"
      ry="4.8"
      width="8"
      height="24"
      fill="currentColor"
      transform="rotate(330 50 50)"
    >
      <animate
        attributeName="opacity"
        values="1;0"
        keyTimes="0;1"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
      />
    </rect>
  </SvgIcon>
);
