import type { AxiosError } from "axios";
import { IErrorModalState } from "./listing/types";
import { ILanguage } from "./config/types";
import get from "lodash.get";
import { interpolate } from "@lib/common";

export interface IAPIResponse<T> {
  responseCode: number;
  responseMessage: string;
  response: T;
}

export interface IErrors {
  errors: IError[];
}

interface IError {
  errorCode: string;
  errorTitle: string;
  errorDescription: string;
  errorDebugDescription: string;
  errorAttributes: any;
}

interface IIntegrationErrorAttributes {
  integrationType: string;
  reason: string;
  rollback: boolean;
  context?: any;
}

interface IIntegrationError extends IError {
  errorAttributes: IIntegrationErrorAttributes;
}

export interface IFetchStatus {
  loading: boolean;
  error: boolean;
  success: boolean;
  errorCode?: string;
}

export function extractErrorCode(error: any) {
  let errorCode = "";
  if (error && error.isAxiosError) {
    const axiosError = error as AxiosError<IAPIResponse<IErrors>>;
    if (axiosError.response) {
      const errorResponse = axiosError.response.data.response;
      if (errorResponse && errorResponse.errors) {
        if (errorResponse.errors.length)
          errorCode = errorResponse.errors[0].errorCode;
      }
    }
  }
  return errorCode;
}

export function extractApplicationError(error: any): IError | null {
  let applicationError: IError | null = null;
  if (error && error.isAxiosError) {
    const axiosError = error as AxiosError<IAPIResponse<IErrors>>;
    if (axiosError.response) {
      const errorResponse = axiosError.response.data.response;
      if (errorResponse && errorResponse.errors) {
        if (errorResponse.errors.length)
          applicationError = errorResponse.errors[0];
      }
    }
  }
  return applicationError;
}

export function extractIntegrationError(error: any): IIntegrationError | null {
  const applicationError = extractApplicationError(error);
  if (applicationError) {
    try {
      const integrationError = applicationError as IIntegrationError;
      return integrationError;
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  return null;
}

export function getCustomErrorMessage(
  errorCode: string,
  language: ILanguage,
  defaultValue: string,
  context?: any
): string {
  const message = get(language.errors, errorCode, defaultValue);
  return interpolate(message, { ...context });
}

export function getUserEligibilityErrorModalState(
  errorCode: string,
  language: ILanguage,
  context?: any
): IErrorModalState {
  const state: IErrorModalState = {
    open: true,
    title: language.errors.UNABLE_TO_PROCEED_HEADER,
    message: language.errors.UNABLE_TO_PROCEED_MESSAGE
  };

  state.message = getCustomErrorMessage(
    errorCode,
    language,
    language.errors.UNABLE_TO_PROCEED_MESSAGE,
    context
  );

  return state;
}

export function getTechnicalErrorModalState(
  errorCode: string,
  language: ILanguage,
  context?: any
): IErrorModalState {
  const state: IErrorModalState = {
    open: true,
    title: language.errors.SOMETHING_WENT_WRONG_HEADER,
    message: language.errors.SOMETHING_WENT_WRONG_MESSAGE
  };

  state.message = getCustomErrorMessage(
    errorCode,
    language,
    language.errors.SOMETHING_WENT_WRONG_MESSAGE,
    context
  );

  return state;
}

export function getIntegrationErrorModalState(
  integrationType: any,
  reason: any,
  context: any,
  language: ILanguage
): IErrorModalState {
  const state: IErrorModalState = {
    open: true,
    title: language.errors.UNABLE_TO_PROCEED_HEADER,
    message: language.errors.UNABLE_TO_PROCEED_MESSAGE
  };

  const integrationLanguage = get(language.integrations, integrationType);
  if (integrationLanguage) {
    const integrationErrorlanguage = get(integrationLanguage.errors, reason);
    if (integrationErrorlanguage) {
      state.title = get(integrationErrorlanguage, "title", state.title);

      const errorMessage = get(
        integrationErrorlanguage,
        "message",
        state.message
      );
      state.message = interpolate(errorMessage, { ...context });
    }
  }
  return state;
}
