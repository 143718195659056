import React, {
  Suspense,
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
} from "react";
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { connect } from "react-redux";
import { NavProvider, Header } from "acm-components";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, Switch, useHistory } from "react-router-dom";

import theme from "../theme";
import AcmGlobalStyles from "../theme/AcmGlobalStyles";

import settings from "@settings";
import { AppState } from "@store";

import Maintenance from "@components/Maintenance";
import ScrollToTop from "@components/ScrollToTop";
import ErrorHandler from "@components/ErrorHandler";

import { sessionRemove } from "@lib/storage";
import LiveChatWidget from "@containers/LiveChatWidget";
import { SESSION_SELECTED_SORT } from "@constants/storage";

import { checkToken } from "@ducks/coupon";
import { getSmartCards } from "@ducks/profile";
import { getConfig, isProd } from "@ducks/config";
import type { IULMProfile } from "@ducks/profile/types";
import { setLoginUrl, checkLoggedIn } from "@ducks/profile";
import { getListing, changeLanguage } from "@ducks/listing";
import { getSideContentListing } from "@ducks/sideContentListing";
import type { ICategory, ITEMTYPE, LANGUAGE, SortOrder } from "@ducks/listing/types";
import { getCategories } from "@ducks/categories";

const Listing = React.lazy(() => import("@containers/Listing"));
const Details = React.lazy(() => import("@containers/Details"));
const Footer = React.lazy(() => import("@components/Footer"));
const NotFound = React.lazy(() => import("@components/NotFound"));

interface IStateProps {
  maintenanceMode: boolean;
  itemType: ITEMTYPE | null;
  language: LANGUAGE;
  defaultSort: string;
  isLoggedIn: boolean;
  portal: number | null;
  isApp: boolean;
  categories: ICategory[]
}

interface IAppProps extends IStateProps {
  getSmartCards: () => void;
  getConfig: () => void;
  getListing: (
    type: ITEMTYPE,
    portal: number | null,
    sortBy?: string,
    sortOrder?: SortOrder,
    language?: LANGUAGE
  ) => void;
  getCategories: (
    language?: LANGUAGE
  ) => void;
  getSideContentListing: (
    type: ITEMTYPE,
    portal: number | null,
    sortOrder?: SortOrder,
    language?: LANGUAGE
  ) => void;
  changeLanguage: (language: LANGUAGE) => void;
  checkToken: () => void;
  setLoginUrl: (loginUrl: string) => void;
  checkLoggedIn: (ulm: IULMProfile | null) => void;
}

const App: FunctionComponent<IAppProps> = (props) => {
  const {
    maintenanceMode,
    itemType,
    defaultSort,
    getConfig,
    getListing,
    getCategories,
    checkToken,
    setLoginUrl,
    getSmartCards,
    checkLoggedIn,
    isLoggedIn,
    portal,
    isApp,
    language,
    changeLanguage,
    getSideContentListing,
  } = props;

  const history = useHistory();
  const [url, setUrl] = useState<string>("");

  const ulmProfileEventListener = ((e: CustomEvent<IULMProfile | null>) =>
    checkLoggedIn(e.detail)) as EventListener;

  const loginUrlEventListener = ((e: CustomEvent<string>) =>
    setUrl(e.detail)) as EventListener;

  useEffect(() => {
    window.addEventListener("login_url", loginUrlEventListener);
    window.addEventListener("ulm_profile", ulmProfileEventListener);

    return (): void => {
      sessionRemove(SESSION_SELECTED_SORT);
      window.removeEventListener("login_url", loginUrlEventListener);
      window.removeEventListener("ulm_profile", ulmProfileEventListener);
    };
  });

  useEffect(() => {
    setLoginUrl(url);
  }, [setLoginUrl, url]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  useEffect(() => {
    if (itemType !== null) {
      getListing(itemType, portal, defaultSort, "asc", language);
      getSideContentListing(itemType, portal, "desc", language);
    }
  }, [
    portal,
    itemType,
    language,
    getListing,
    defaultSort,
    getSideContentListing,
  ]);

  useEffect(()=>{
    if (itemType !== null) {
      getCategories(language)
    }

  },[getCategories,language])

  // Note: Get the translation based on language
  useEffect(() => {
    getConfig();
  }, [getConfig, language]);

  useEffect(() => {
    getSmartCards();
  }, [getSmartCards, isLoggedIn]);

  const onLanguageChange = useCallback((language: any) => {
    changeLanguage(language);

    const { pathname, search } = history.location;

    if (language === "en") {
      const newPathname = pathname.replace("/bm", "").concat(search);
      history.push(newPathname);
    }
    if (language === "bm") {
      const includesBm =
        (pathname.includes("/bm") && !pathname.includes("/details")) ||
        pathname.includes("/bm/");
      history.push(
        includesBm
          ? pathname.concat(search)
          : `/${language}${pathname}${search}`
      );
    }
  }, []);


  return (
    <StyledEngineProvider>
      <HelmetProvider>
        <Helmet>
          <script
            async
            type="text/javascript"
            src={
              isProd
                ? "//cdn.evgnet.com/beacon/measatbns/astro_production/scripts/evergage.min.js"
                : "//cdn.evgnet.com/beacon/measatbns/astro_staging/scripts/evergage.min.js"
            }
          />
          {isProd && (
            <script
              async
              type="text/javascript"
              src="https://acm-homepage-static.eco.astro.com.my/scripts/newrelic/newrelic_acm_promotions.js"
            />
          )}
        </Helmet>

        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AcmGlobalStyles />
          <NavProvider
            portal="acm"
            environment={settings.navEnv}
            onLocaleChange={onLanguageChange}  dfEndpoint={settings.navDFendPoint}
          >
            {({ config, locale }) => {
              return (
                <>
                  <div style={{ display: isApp ? "none" : undefined }}>
                    <Header config={config.header} locale={locale} />
                  </div>
                  <main>
                    <ScrollToTop />
                    <Maintenance maintenanceMode={maintenanceMode}>
                      <ErrorHandler>
                        <Switch>
                          <Route exact path="/:language?">
                            <Suspense fallback={null}>
                              <Listing />
                            </Suspense>
                          </Route>                         
                          <Route path="/:language?/details/:code">
                            <Suspense fallback={null}>
                              <Details />
                            </Suspense>
                          </Route>
                          <Route exact path="/:language/:category">
                            <Suspense fallback={null}>
                              <Listing />
                            </Suspense>
                          </Route>
                          <Route>
                            <Suspense fallback={null}>
                              <NotFound />
                            </Suspense>
                          </Route>
                        </Switch>
                      </ErrorHandler>
                    </Maintenance>
                    <LiveChatWidget config={config.liveChat} />
                  </main>

                  {!isApp && (
                    <Suspense fallback={null}>
                      <Footer config={config.footer} />
                    </Suspense>
                  )}
                </>
              );
            }}
          </NavProvider>
        </ThemeProvider>
      </HelmetProvider>
    </StyledEngineProvider>
  );
};

const mapStateToProps = (state: AppState): IStateProps => ({
  maintenanceMode: state.config.app.maintenanceMode,
  itemType: state.listing.itemType,
  language: state.listing.language,
  defaultSort: state.config.listing.defaultSort,
  isLoggedIn: state.profile.isLoggedIn,
  portal: state.config.portal,
  isApp: state.config.app.isApp,
  categories: state.categories.categories
});

const mapDispatchToProps = {
  changeLanguage,
  getConfig,
  getSmartCards,
  getListing,
  getCategories,
  getSideContentListing,
  checkToken,
  setLoginUrl,
  checkLoggedIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
