import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import ErrorOverlay from "@components/ErrorOverlay";

import { AppState } from "@store";

export interface MaintenanceHandlerProps {
  children: React.ReactElement;
  maintenanceMode: boolean;
}

const Maintenance: FunctionComponent<MaintenanceHandlerProps> = ({
  maintenanceMode,
  children
}) => {
  const language = useSelector((state: AppState) => state.config.language);
  const {
    MAINTENANCE_MODE_TITLE: title,
    MAINTENANCE_MODE_MESSAGE: message
  } = language;

  return maintenanceMode ? (
    <ErrorOverlay title={title} message={message} />
  ) : (
    children
  );
};

Maintenance.displayName = "Maintenance";

export default Maintenance;
