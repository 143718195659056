import React, { FunctionComponent, useCallback } from "react";
import { ButtonProps, Button as MuiButton, useTheme } from "@mui/material";

import mergeSx from "@lib/mergeSx";
import { SpinnerIcon } from "@components/SvgIcon";

export interface IButtonProps extends ButtonProps {
  dark?: boolean;
  pink?: boolean;
  magenta?: boolean;
  isLoading?: boolean;
  ga?: Record<string, string>;
  disabled?: boolean;
}

const Button: FunctionComponent<IButtonProps> = (props) => {
  const {
    dark,
    pink,
    magenta,
    onClick,
    disabled,
    isLoading,
    sx: sxProp,
    variant = "outlined",
    disableRipple = false,
    ...otherProps
  } = props;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => !isLoading && onClick?.(e),
    [isLoading, onClick]
  );
  const { palette } = useTheme();
  const spinnerColor = disabled ? palette.text.disabled : palette.common.white;

  return (
    <MuiButton
      variant={variant}
      disabled={disabled}
      onClick={handleClick}
      disableRipple={disableRipple}
      endIcon={isLoading && <SpinnerIcon htmlColor={spinnerColor} />}
      sx={mergeSx(
        ({ palette }) => ({
          padding: "11px 18px",
          height: "48px",
          lineHeight: 1,
          color:
            dark || magenta || pink
              ? palette.common.white
              : palette.common.black,
          borderStyle: "solid",
          borderWidth: magenta ? 8 : 2,
          borderColor:
            magenta || pink
              ? "transparent"
              : dark
              ? palette.common.white
              : palette.common.black,
          borderRadius: "8px",
          backgroundColor:
            magenta || pink ? palette.primary.main : "transparent",
          backgroundImage:
            magenta || pink
              ? "linear-gradient(to right, #e6007d, #e72f3b)"
              : "",
          fontSize: "1rem",
          fontWeight: 700,
          cursor: "pointer",
          transition: "border 200ms ease, background-color 200ms ease",
          "&:hover": {
            backgroundColor:
              magenta || pink ? palette.primary.main : "transparent",
            backgroundImage:
              magenta || pink
                ? "linear-gradient(to right, #cf0071, #d0283a)"
                : "",
            borderColor:
              magenta || pink
                ? "transparent"
                : dark
                ? palette.common.white
                : palette.common.black,
            borderStyle: "solid",
            borderWidth: magenta ? 8 : 2,
          },
          "&.Mui-disabled": {
            color: palette.grey[400],
            backgroundColor:
              // dark || magenta || pink
              //   ? "rgba(255, 255, 255, 0.15)"
              //   : "rgba(0, 0, 0, 0.15)",
              palette.grey[300],
            backgroundImage: "none",
            border: "solid 2px transparent",
            cursor: "not-allowed",
          },
        }),
        sxProp
      )}
      {...otherProps}
    />
  );
};

Button.defaultProps = {
  dark: false,
  magenta: false,
};

export default Button;
