import { IItem, ITEMTYPE, INTEGRATION_TYPE } from "@ducks/listing/types";
import { ILanguage } from "@ducks/config/types";

export function interpolate(
  str: string,
  params: { [key: string]: string }
): string {
  if (!Object.entries) {
    Object.entries = function (obj: { [x: string]: any }) {
      const ownProps = Object.keys(obj);
      let i = ownProps.length;
      const resArray = new Array(i); // preallocate the Array
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];
      return resArray;
    };
  }
  for (const [key, value] of Object.entries(params)) {
    const regex = RegExp(`{${key}}`, "g");
    let matches;
    while ((matches = regex.exec(str)) !== null)
      str = str.replace(matches[0], value);
  }
  return str;
}

export function getEncodedUrl(url: string): string {
  try {
    return new URL(url).href;
  } catch (e) {
    return url;
  }
}

export function dedupe<T>(array: Array<T>) {
  return array.filter((item, index) => array.indexOf(item) === index);
}

export function isReward(item: IItem | null): boolean {
  if (item) if (item.type.toLowerCase() === ITEMTYPE.reward) return true;
  return false;
}

export function isIntegrationNeeded(item: IItem | null): boolean {
  if (item) {
    return item.requireThirdPartyIntegration;
  }
  return false;
}

export function isPromotion(item: IItem | null): boolean {
  if (item) if (item.type.toLowerCase() === ITEMTYPE.promotion) return true;
  return false;
}

export function getDefaultRedeemButtonText(
  item: IItem | null,
  language: ILanguage
): string {
  if (item) {
    if (isIntegrationNeeded(item) || isPromotion(item)) {
      return language.details.JOIN_NOW;
    }
  }
  return language.details.REDEEM;
}

export function getRedeemedButtonText(
  item: IItem | null,
  language: ILanguage
): string {
  if (item) {
    if (isIntegrationNeeded(item) || isPromotion(item)) {
      return language.details.JOIN_NOW;
    }
  }
  return language.details.REDEEMED;
}

export function redirectToLogin(loginUrl: string): void {
  window.location.href = interpolate(loginUrl, {
    location: encodeURIComponent(getEncodedUrl(window.location.href)),
  });
}

export function typeOfIntegration(item: IItem | null): INTEGRATION_TYPE | null {
  if (item && item.typeOfIntegration) {
    return item.typeOfIntegration;
  }
  return null;
}

export function formatDuration(minutes: number): string {
  const MINUTES_IN_DAY = 24 * 60;

  const numDays = Math.floor(minutes / MINUTES_IN_DAY);
  const numHours = Math.floor(minutes / 60);

  let duration = 0;
  let unit = "";

  if (numDays) {
    duration = numDays;
    unit = "day" + (numDays > 1 ? "s" : "");
  } else if (numHours) {
    duration = numHours;
    unit = "hour" + (numHours > 1 ? "s" : "");
  } else {
    duration = minutes;
    unit = "minute" + (minutes > 1 ? "s" : "");
  }

  return duration.toString() + " " + unit;
}

export function checkAndSetQueryParam(url: string, queryParamSet: any): string {
  try {
    const location = new URL(url);
    location.searchParams.append(queryParamSet.key, queryParamSet.val);
    return location.toString();
  } catch (e) {
    return url;
  }
}
