import { PaletteOptions } from "@mui/material";

const palette: PaletteOptions = {
  mode: "light",
  primary: {
    main: "#e6007d",
    light: "#e6007d",
    dark: "#e72f3b",
    contrastText: "#fff",
  },
  secondary: {
    main: "#1d7fe3",
    contrastText: "#fff",
  },
  error: {
    light: "#fad9d9",
    main: "#ff0000",
    contrastText: "#d2181e",
  },
  warning: {
    main: "#fff2b2",
    dark: "#ffd501",
    contrastText: "#f84d1d",
  },
  success: {
    main: "#d3f9b2",
    contrastText: "#167e14",
  },
  common: {
    black: "#010414",
    white: "#fff",
  },
  grey: {
    50: "#f8f8f8", // disabled bg, disabled input bg
    100: "#f0f0f0", // unselected card selector
    200: "#e9e9ee", // section divider
    300: "#d8d8d8", // grey borders, radio, checkbox, disabled button
    400: "#b2b3b8", // divider border
    600: "#86878b", // disabled text, hint text, darker border
    700: "#707070",
    800: "#4d4f5a", // monthly fee label
    900: "#F6F6F8", //Applied promocode in shortform
  },
  text: {
    primary: "#010414",
    disabled: "#b2b3b8",
  },
  divider: "#d8d8d8",
  background: {
    default: "#fff",
    paper: "#fff",
  },
};

export default palette;
