import { IRecordStatus } from "@ducks/coupon/types";

export interface IULMProfile {
  sub: string;
  name: string;
  preferred_username?: string;
  updated_at: string;
  EPUID?: string;
  PUID: string;
  astro_entitlements: IULMAccounts;
  ulmProfileToken?: string;
  user_accountNumber?: string;
}

export interface IULMProfileV2 {
  profile: IProfileV2;
}
export interface IProfileV2 {
  user: IUserV2;
}

export interface IUserV2 {
  displayAccountId: string;
}

interface IULMAccounts {
  accounts: IEntitlements[];
}

export interface IEntitlements {
  accountNumber: string;
  subscriptions: ISubscriptions[];
  status: Status;
}

export interface ISubscriptions {
  subscriptionNumber: string;
  status: Status;
}

export enum Status {
  active = "ACTIVATED",
  deactive = "DEACTIVATED",
}

export interface IULMAuthResponse {
  token: string;
  type: string;
}

export interface IStatusResponse {
  couponValidity: number;
  smcStatus: ISmartCardsStatus;
  recordStatus: IRecordStatus;
}

export interface ISmartCardsStatus {
  [key: string]: ISmartCardStatus;
}

export interface ISmartCardStatus {
  canAvail: boolean;
  couponValidity?: number;
  reason?: SmartCardStatus;
  coupon?: string;
  createdAt?: string;
  expiresAt?: string;
  remainingTimeInMilli?: number;
}

export enum SmartCardStatus {
  inactive = "INACTIVE_SMC",
  invalid = "INVALID_SMC",
  redeemed = "ALREADY_REDEEM",
  unavailable = "COUPON_NOT_AVAILABLE",
  finished = "COUPON_FINISHED",
  couponDown = "COUPON_SERVICE_DOWN",
  redeemDown = "REDEEM_SERVICE_DOWN",
}
