import type { SxProps, Theme } from '@mui/material';
import type { Maybe } from 'acm-components';

/**
 * Merge multiple `sx` prop into one.
 * @param args Array of `sx` prop.
 * @returns Combined `sx` value.
 */
export default function mergeSx(
  ...args: Maybe<SxProps<Theme>>[]
): SxProps<Theme> {
  return args.reduce<SxProps<Theme>>(
    (acc, sx = []) =>
      Array.isArray(acc) ? [...acc, ...(Array.isArray(sx) ? sx : [sx])] : acc,
    []
  );
}
