import { NavEnvironment } from "acm-components";
import json from "settings.json";

interface ISettings {
  navEnv: NavEnvironment;
  navDFendPoint?:string;
  env: string;
  version: string;
  barcodeFormat: string;
  config: {
    url: string;
    key: {
      app: string;
      listing: string;
      verification: string;
      language: string;
      meta: string;
      resizer: string;
      portal: string;
    };
  };
  promotions: {
    webUrl: string;
  },
  rewards: {
    webUrl: string;
    getUserInfoUrl: string;
    domain: string;
    list: string;
    redeemStatus: string;
    redeem: string;
    authenticate: string;
  };
  gwe: {
    chatEndpoint: string;
    callbackEndpoint: string;
    eventName: string;
    timeouts: number[];
  };
  categoryOptions:string;
}

const settings: ISettings = json as ISettings;

export default settings;
