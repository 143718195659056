import React from "react";
import { Box, Typography } from "@mui/material";

import Button from "@components/Button";
import Container from "@components/Container";

export interface ErrorMessageProps {
  heading: string;
  subHeading?: string;
  buttonText?: string;
  buttonUrl?: string;
  onClick?: React.MouseEventHandler;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  heading,
  subHeading,
  buttonText,
  buttonUrl,
  onClick
}) => (
  <Container>
    <Box mt={{ xs: 3, md: 6 }} width={{ lg: "calc(60% - 1rem)" }}>
      <>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ lineHeight: "34px" }} variant="h1">
            {heading}
          </Typography>
        </Box>
        {subHeading && (
          <Typography
            sx={{ fontWeight: 400 }}
            dangerouslySetInnerHTML={{ __html: subHeading }}
          />
        )}
      </>
      {buttonText && (
        <Box mt={4}>
          {!!buttonText && (
            <a href={buttonUrl ?? "/"}>
              <Button
                magenta
                disableRipple
                variant="contained"
                sx={{
                  width: "100%",
                  borderWidth: 0,
                  "&:hover": { borderWidth: 0 }
                }}
                onClick={onClick}
              >
                {buttonText}
              </Button>
            </a>
          )}
        </Box>
      )}
    </Box>
  </Container>
);

export default ErrorMessage;
