import React, { FunctionComponent } from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import Button from "@components/Button";

interface IErrorProps {
  title: string;
  message: string;
  buttonText?: string;
  buttonUrl?: string;
  reload?: boolean;
}

const ErrorOverlay: FunctionComponent<IErrorProps> = props => {
  const { title, message, buttonText, buttonUrl, reload = false } = props;

  return (
    <>
      <Typography
        component={"h1"}
        sx={({ breakpoints }) => ({
          mb: 2,
          fontWeight: 800,
          fontSize: "1.75rem",
          lineHeight: "2.125rem",
          [breakpoints.down("sm")]: {
            mt: 1,
            fontSize: "1.5rem",
            lineHeight: "1.875rem"
          }
        })}
      >
        {title}
      </Typography>

      <Typography paragraph sx={{ lineHeight: "1.375rem", m: 0 }}>
        {message}
      </Typography>

      {buttonText &&
        buttonUrl &&
        (reload ? (
          <a href={buttonUrl} style={{ textDecoration: "none" }}>
            <Button
              magenta
              sx={{
                mt: 4,
                minWidth: 156,
                borderWidth: 0,
                lineHeight: "1.375rem"
              }}
            >
              {buttonText}
            </Button>
          </a>
        ) : (
          <Link to={buttonUrl} style={{ textDecoration: "none" }}>
            <Button
              magenta
              sx={{
                mt: 4,
                minWidth: 156,
                borderWidth: 0,
                lineHeight: "1.375rem"
              }}
            >
              {buttonText}
            </Button>
          </Link>
        ))}
    </>
  );
};

ErrorOverlay.displayName = "ErrorOverlay";

ErrorOverlay.defaultProps = {
  title: "Oops, something went wrong",
  message:
    "Apologies for the inconvenience, but rest assured we’re working on it. Please try again later.",
  buttonText: "",
  buttonUrl: "",
  reload: false
};

export default ErrorOverlay;
