import { IAPIResponse, extractErrorCode } from "@ducks/common";
import { LANGUAGE, LANGUAGES } from "@ducks/listing/types";
import settings from "@settings";
import axios from "axios";
import { Action, ActionCreator, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";

interface ICategory {
  code: string;
  name: string;
  enabled: boolean;
}

// Action Types

const FETCH_CATEGORIES_REQUEST = "categories/FETCH_CATEGORIES_REQUEST";
const FETCH_CATEGORIES_SUCCESS = "categories/FETCH_CATEGORIES_SUCCESS";
const FETCH_CATEGORIES_FAILURE = "categories/FETCH_CATEGORIES_FAILURE";

// Action Interfaces

interface IFetchCategoriesRequest
  extends Action<typeof FETCH_CATEGORIES_REQUEST> {}
interface IFetchCategoriesFailure
  extends Action<typeof FETCH_CATEGORIES_FAILURE> {
  payload: string;
}
interface IFetchCategoriesSuccess
  extends Action<typeof FETCH_CATEGORIES_SUCCESS> {
  payload: ICategory[];
}

type categoriesActionTypes =
  | IFetchCategoriesRequest
  | IFetchCategoriesSuccess
  | IFetchCategoriesFailure;

interface ICategoryState {
  categories: ICategory[];
  loading: boolean;
  error: boolean;
}

const initialState = {
  loading: false,
  error: false,
  categories: [],
};

const categoriesReducer: Reducer<ICategoryState, categoriesActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
      return { ...state, loading: true };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
        error: false,
      };
    case FETCH_CATEGORIES_FAILURE:
      // eslint-disable-next-line no-case-declarations
      const errorCode = action.payload ? { errorCode: action.payload } : {};
      return {
        ...state,
        ...errorCode,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
export default categoriesReducer;

// Actions
export const getCategories: ActionCreator<
  ThunkAction<
    void,
    ICategoryState,
    void,
    IFetchCategoriesRequest | IFetchCategoriesSuccess | IFetchCategoriesFailure
  >
> = (language?: LANGUAGE) => async (dispatch) => {
  dispatch({ type: FETCH_CATEGORIES_REQUEST });
  try {
    const lang = language ? LANGUAGES[language] : LANGUAGES.eng;

    const url = `${settings.categoryOptions}${lang}.json`;
    const response = await axios.get<IAPIResponse<ICategory[]>>(url);
    const {
      data: { response: items },
    } = response;

    const filteredItems = items.filter((item) => item.enabled);
    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      payload: filteredItems,
    });
  } catch (e) {
    const errorCode = extractErrorCode(e);
    dispatch({ type: FETCH_CATEGORIES_FAILURE, payload: errorCode });
  }
};
