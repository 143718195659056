import { ReportHandler } from "web-vitals";

declare let dataLayer: any[];

export interface IGtmEvent {
  [key: string]: any;
}

export function push(event: IGtmEvent) {
  try {
    dataLayer.push(event);
  } catch (e) {
    console.error("Error: GTM container not loaded");
  }
}

export const vitalsMetricHandler: ReportHandler = metric => {
  const { name, delta, id } = metric;
  push({
    event: "web-vitals",
    event_category: "Web Vitals",
    event_action: name,
    event_value: Math.round(name === "CLS" ? delta * 1000 : delta),
    event_label: id
  });
};
