import axios, { AxiosRequestConfig } from "axios";
import { ThunkAction } from "redux-thunk";
import { Action, ActionCreator, Reducer } from "redux";
import settings from "@settings";
import { IAPIResponse, extractErrorCode } from "@ducks/common";
import {
  IItem,
  ITEMTYPE,
  LANGUAGE,
  SortOrder,
  IErrorModalState,
  IDropdown,
  ShortFormPostParams,
} from "./types";
import type { AppState } from "@store";
import { IStatusResponse, IULMProfile } from "@ducks/profile/types";
import { cookieGet } from "@lib/storage";
import { ULM_ACCESS_TOKEN_COOKIE_NAME } from "@constants/storage";
import { isDev } from "@ducks/config";
import { IShortFormValues } from "@schemas/shortForm";

// Action Types
const GET_LISTING = "listing/GET_LISTING";
const GET_LISTING_ERROR = "listing/GET_LISTING_ERROR";
const GET_LISTING_SUCCESS = "listing/GET_LISTING_SUCCESS";
const GET_DETAILS = "listing/GET_DETAILS";
const GET_DETAILS_ERROR = "listing/GET_DETAILS_ERROR";
const RESET_DETAILS = "listing/RESET_DETAILS";
const UPDATE_ITEM_TYPE = "listing/UPDATE_ITEM_TYPE";
const CHANGE_LANGUAGE = "listing/CHANGE_LANGUAGE";
const UPDATE_SORT = "listing/UPDATE_SORT";
const TOGGLE_FOOTER_VISIBILITY = "listing/TOGGLE_FOOTER_VISIBILITY";
const TOGGLE_CODE_MODAL = "listing/TOGGLE_CODE_MODAL";
const TOGGLE_ALREADY_REDEEMED_MODAL = "listing/TOGGLE_ALREADY_REDEEMED_MODAL";
const TOGGLE_THANK_YOU_MODAL = "listing/THANK_YOU_MODAL";
const TOGGLE_LEAD_FORM_MODAL = "listing/LEAD_FORM_MODAL";
const TOGGLE_UNABLE_TO_PROCEED_MODAL = "listing/UNABLE_TO_PROCEED_MODAL";
const TOGGLE_CIRCULAR_PROGRESS_INDICATOR =
  "listing/CIRCULAR_PROGRESS_INDICATOR";
const TOGGLE_SHORT_FORM_MODAL = "listing/TOGGLE_SHORT_FORM_MODAL";
const TOGGLE_SHORT_FORM_THANK_YOU = "listing/TOGGLE_SHORT_FORM_THANK_YOU";
const TOGGLE_SPINNER = "listing/TOGGLE_SPINNER";
const TOGGLE_ERROR_MODAL = "listing/TOGGLE_ERROR_MODAL";
const TOGGLE_APPLY_PROMOCODE = "listing/TOGGLE_APPLY_PROMOCODE";
const HAS_PROMOCODE_ERROR = "listing/HAS_PROMOCODE_ERROR";

// Action Interfaces
interface IGetListing extends Action<typeof GET_LISTING> {}
interface IGetListingError extends Action<typeof GET_LISTING_ERROR> {
  payload: string;
}
interface IGetListingSuccess extends Action<typeof GET_LISTING_SUCCESS> {
  payload: {
    allItems: IItem[];
    items: IItem[];
  };
}
interface IGetDetails extends Action<typeof GET_DETAILS> {
  payload: IItem;
}
interface IGetDetailsError extends Action<typeof GET_DETAILS_ERROR> {}
interface IResetDetails extends Action<typeof RESET_DETAILS> {}
interface IUpdateItemType extends Action<typeof UPDATE_ITEM_TYPE> {
  payload: ITEMTYPE;
}
interface IChangeLanguage extends Action<typeof CHANGE_LANGUAGE> {
  payload: LANGUAGE;
}
interface IUpdateSort extends Action<typeof UPDATE_SORT> {
  payload: {
    sortBy: string;
    sortOrder: SortOrder;
  };
}
interface IToggleFooterVisibility
  extends Action<typeof TOGGLE_FOOTER_VISIBILITY> {
  payload: boolean;
}
interface IToggleCodeModal extends Action<typeof TOGGLE_CODE_MODAL> {
  payload: boolean;
}
interface IToggleAlreadyRedeemedModal
  extends Action<typeof TOGGLE_ALREADY_REDEEMED_MODAL> {
  payload: boolean;
}
interface IToggleThankYouModal extends Action<typeof TOGGLE_THANK_YOU_MODAL> {
  payload: boolean;
}

interface IToggleLeadFormModal extends Action<typeof TOGGLE_LEAD_FORM_MODAL> {
  payload: boolean;
}

interface IToggleUnableToProceedModal
  extends Action<typeof TOGGLE_UNABLE_TO_PROCEED_MODAL> {
  payload: IErrorModalState;
}

interface IToggleCircularProgressIndicator
  extends Action<typeof TOGGLE_CIRCULAR_PROGRESS_INDICATOR> {
  payload: boolean;
}

interface IToggleShortFormModal extends Action<typeof TOGGLE_SHORT_FORM_MODAL> {
  payload: boolean;
}

interface IToggleShortFormThankYou
  extends Action<typeof TOGGLE_SHORT_FORM_THANK_YOU> {
  payload: boolean;
}

interface IToggleSpinner extends Action<typeof TOGGLE_SPINNER> {
  payload: boolean;
}

interface IToggleError extends Action<typeof TOGGLE_ERROR_MODAL> {
  payload: boolean;
}

//Spinner for Promocode
interface IToggleApplyPromocode extends Action<typeof TOGGLE_APPLY_PROMOCODE> {
  payload: boolean;
}

//Check canAvail for Promocode
interface IHasPromocodeError extends Action<typeof HAS_PROMOCODE_ERROR> {
  payload: boolean;
}

type ListingActionTypes =
  | IGetListing
  | IGetListingError
  | IGetListingSuccess
  | IGetDetails
  | IGetDetailsError
  | IResetDetails
  | IUpdateItemType
  | IUpdateSort
  | IChangeLanguage
  | IToggleFooterVisibility
  | IToggleCodeModal
  | IToggleAlreadyRedeemedModal
  | IToggleThankYouModal
  | IToggleLeadFormModal
  | IToggleUnableToProceedModal
  | IToggleCircularProgressIndicator
  | IToggleShortFormModal
  | IToggleShortFormThankYou
  | IToggleSpinner
  | IToggleError
  | IToggleApplyPromocode
  | IHasPromocodeError;

// Reducers
interface IListingState {
  itemType: ITEMTYPE | null;
  language: LANGUAGE;
  allItems: IItem[];
  items: IItem[];
  loading: boolean;
  error: boolean;
  errorCode: string;
  success: boolean;
  sortBy: string;
  sortOrder: SortOrder;
  details: IItem | null;
  detailsError: boolean;
  detailsSuccess: boolean;
  footerVisibility: boolean; // NOTE: to prevent page flicker when details page loads
  codeModal: boolean;
  alreadyRedeemedModal: boolean;
  thankYouModal: boolean;
  leadFormModal: boolean;
  unableToProceedModal: IErrorModalState;
  circularProgressIndicator: boolean;
  shortFormModal: boolean;
  shortFormThankYou: boolean;
  toggleSpinner: boolean;
  hasFormSubmissionError: boolean;
  toggleApplyPromocode: boolean;
  hasPromocodeError: boolean | undefined;
  hasPromocodeErrorToggle: boolean;
  dropdownListOne: IDropdown;
  dropdownListTwo: IDropdown;
  dropdownListThree: IDropdown;
}

const initialState: IListingState = {
  itemType: ITEMTYPE.all,
  language: LANGUAGE.en,
  allItems: [],
  items: [],
  loading: false,
  error: false,
  errorCode: "",
  success: false,
  sortBy: "featured",
  sortOrder: "asc",
  details: null,
  detailsError: false,
  detailsSuccess: false,
  footerVisibility: true,
  codeModal: false,
  alreadyRedeemedModal: false,
  thankYouModal: false,
  leadFormModal: false,
  unableToProceedModal: {
    open: false,
    title: "",
    message: "",
  },
  circularProgressIndicator: false,
  shortFormModal: false,
  shortFormThankYou: false,
  toggleSpinner: false,
  hasFormSubmissionError: false,
  toggleApplyPromocode: false,
  hasPromocodeError: undefined,
  hasPromocodeErrorToggle: false,
  dropdownListOne: { Options: [], Title: "" },
  dropdownListTwo: { Options: [], Title: "" },
  dropdownListThree: { Options: [], Title: "" },
};

const listingReducer: Reducer<IListingState, ListingActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_LISTING:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case GET_LISTING_ERROR:
      // eslint-disable-next-line no-case-declarations
      const errorCode = action.payload ? { errorCode: action.payload } : {};
      return {
        ...state,
        ...errorCode,
        loading: false,
        error: true,
        success: false,
      };
    case GET_LISTING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false,
        success: true,
      };
    case GET_DETAILS:
      return {
        ...state,
        details: action.payload,
        detailsError: false,
        detailsSuccess: true,
        footerVisibility: true,
      };
    case GET_DETAILS_ERROR:
      return {
        ...state,
        details: null,
        detailsError: true,
        detailsSuccess: false,
        footerVisibility: true,
      };
    case RESET_DETAILS:
      return {
        ...state,
        details: null,
        detailsError: false,
        detailsSuccess: false,
        footerVisibility: true,
      };
    case UPDATE_ITEM_TYPE:
      return {
        ...state,
        itemType: action.payload,
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case UPDATE_SORT:
      return {
        ...state,
        ...action.payload,
      };
    case TOGGLE_FOOTER_VISIBILITY:
      return {
        ...state,
        footerVisibility: action.payload,
      };
    case TOGGLE_CODE_MODAL:
      return {
        ...state,
        codeModal: action.payload,
      };
    case TOGGLE_ALREADY_REDEEMED_MODAL:
      return {
        ...state,
        alreadyRedeemedModal: action.payload,
      };
    case TOGGLE_THANK_YOU_MODAL:
      return {
        ...state,
        thankYouModal: action.payload,
      };
    case TOGGLE_LEAD_FORM_MODAL:
      return {
        ...state,
        leadFormModal: action.payload,
      };
    case TOGGLE_UNABLE_TO_PROCEED_MODAL:
      return {
        ...state,
        unableToProceedModal: action.payload,
      };
    case TOGGLE_CIRCULAR_PROGRESS_INDICATOR:
      return {
        ...state,
        circularProgressIndicator: action.payload,
      };
    case TOGGLE_SHORT_FORM_MODAL:
      return { ...state, shortFormModal: action.payload };
    case TOGGLE_SHORT_FORM_THANK_YOU:
      return { ...state, shortFormThankYou: action.payload };
    case TOGGLE_SPINNER:
      return { ...state, toggleSpinner: action.payload };
    case TOGGLE_ERROR_MODAL:
      return { ...state, hasFormSubmissionError: action.payload };
    case TOGGLE_APPLY_PROMOCODE:
      return { ...state, toggleApplyPromocode: action.payload };
    case HAS_PROMOCODE_ERROR:
      return { ...state, hasPromocodeError: action.payload };
    default:
      return state;
  }
};

export default listingReducer;

// Actions
export const getListing: ActionCreator<
  ThunkAction<
    void,
    IListingState,
    void,
    IGetListing | IGetListingError | IGetListingSuccess
  >
> =
  (
    type: ITEMTYPE,
    portal: number | null,
    sortBy?: string,
    sortOrder?: SortOrder,
    language?: LANGUAGE
  ) =>
  async (dispatch) => {
    dispatch({ type: GET_LISTING });
    try {
      // If portal === null, API will not be called and function will be exited.
      if (!portal) return;
      let url = `${settings.rewards.domain}${settings.rewards.list}?type=${type}`;
      if (sortBy) url += `&sortBy=${sortBy}`;
      if (sortOrder) url += `&sortOrder=${sortOrder}`;
      if (language) url += `&language=${language}`;
      // Attach portal ID parameter to end of API url
      url += `&portal=${isDev ? 6 : portal}`;

      const response = await axios.get<IAPIResponse<IItem[]>>(url);
      const {
        data: { response: items },
      } = response;

      const filteredItems = items.filter((item) => item.isVisibleOnListing);
      dispatch({
        type: GET_LISTING_SUCCESS,
        payload: {
          allItems: items,
          items: filteredItems,
        },
      });
    } catch (e) {
      const errorCode = extractErrorCode(e);
      dispatch({ type: GET_LISTING_ERROR, payload: errorCode });
    }
  };

export const getDetails: ActionCreator<IGetDetails | IGetDetailsError> = (
  code: string,
  items: IItem[]
) => {
  try {
    const details = items.find((item) => item.code === code);
    if (details)
      return {
        type: GET_DETAILS,
        payload: details,
      };
    else throw new Error("Item not found");
  } catch (e) {
    return { type: GET_DETAILS_ERROR };
  }
};

export const generateShortForm: ActionCreator<
  ThunkAction<
    void,
    IListingState,
    void,
    | IToggleSpinner
    | IToggleShortFormThankYou
    | IToggleShortFormModal
    | IToggleError
  >
> =
  (values: NonNullable<IShortFormValues>, code: string, url: string) =>
  async (dispatch) => {
    if (values && code) {
      const payload: ShortFormPostParams = {
        captcha: true,
        promoCampaign: code,
        ...values,
      };
      try {
        dispatch({ type: TOGGLE_SPINNER, payload: true });
        await axios.post(url, payload).then(() => {
          dispatch({
            type: TOGGLE_SPINNER,
            payload: false,
          });
          dispatch({ type: TOGGLE_SHORT_FORM_MODAL, payload: false });
          dispatch({ type: TOGGLE_SHORT_FORM_THANK_YOU, payload: true });
        });
      } catch (e) {
        dispatch({ type: TOGGLE_SPINNER, payload: false });
        dispatch({ type: TOGGLE_ERROR_MODAL, payload: true });
        console.error("Error submitting short form - generateShortForm ", e);
      }
    }
  };

export const promoCodeStatus: ActionCreator<
  ThunkAction<
    void,
    AppState,
    IListingState,
    IToggleApplyPromocode | IToggleError | IHasPromocodeError
  >
> =
  (rewardCode: string, code: string, portal: number) =>
  async (dispatch, getState) => {
    if (rewardCode && code && portal) {
      try {
        const ulmProfile = getState().profile.ulmProfile as IULMProfile;
        const ulmProfileToken = ulmProfile
          ? ulmProfile.ulmProfileToken
          : undefined;
        const authToken = cookieGet(ULM_ACCESS_TOKEN_COOKIE_NAME);
        const url = `${settings.rewards.domain}${settings.rewards.redeemStatus}`;

        const requestConfig: AxiosRequestConfig = authToken
          ? {
              headers: {
                Authorization: authToken ? `Bearer ${ulmProfileToken}` : null,
              },
            }
          : {};
        dispatch({ type: TOGGLE_APPLY_PROMOCODE, payload: true });
        const { data } = await axios.post<IAPIResponse<IStatusResponse>>(
          url,
          { rewardCode, portal: isDev ? 6 : portal , code },
          requestConfig
        );

        if (data.response) {
          dispatch({ type: TOGGLE_APPLY_PROMOCODE, payload: false });
          // payload is false if canAvail is true.
          dispatch({
            type: HAS_PROMOCODE_ERROR,
            payload: !data.response.recordStatus.canAvail,
          });
        }
      } catch {
        dispatch({ type: TOGGLE_ERROR_MODAL, payload: true });
        dispatch({ type: TOGGLE_APPLY_PROMOCODE, payload: false });
      }
    }
  };

export const resetDetails: ActionCreator<IResetDetails> = () => ({
  type: RESET_DETAILS,
});

export const updateItemType: ActionCreator<IUpdateItemType> = (
  itemType: ITEMTYPE
) => ({
  type: UPDATE_ITEM_TYPE,
  payload: itemType,
});

export const changeLanguage: ActionCreator<IChangeLanguage> = (
  language: "bm" | "en"
) => {
  return {
    type: CHANGE_LANGUAGE,
    payload: LANGUAGE[language],
  };
};

export const updateSort: ActionCreator<IUpdateSort> = (
  sortBy: string,
  sortOrder: SortOrder
) => ({
  type: UPDATE_SORT,
  payload: {
    sortBy,
    sortOrder,
  },
});

export const toggleFooterVisibility: ActionCreator<IToggleFooterVisibility> = (
  state: boolean
) => ({
  type: TOGGLE_FOOTER_VISIBILITY,
  payload: state,
});

export const toggleCodeModal: ActionCreator<IToggleCodeModal> = (
  state: boolean
) => ({
  type: TOGGLE_CODE_MODAL,
  payload: state,
});

export const toggleAlreadyRedeemedModal: ActionCreator<
  IToggleAlreadyRedeemedModal
> = (state: boolean) => ({
  type: TOGGLE_ALREADY_REDEEMED_MODAL,
  payload: state,
});

export const toggleThankYouModal: ActionCreator<IToggleThankYouModal> = (
  state: boolean
) => ({
  type: TOGGLE_THANK_YOU_MODAL,
  payload: state,
});

export const toggleLeadFormModal: ActionCreator<IToggleLeadFormModal> = (
  state: boolean
) => ({
  type: TOGGLE_LEAD_FORM_MODAL,
  payload: state,
});

export const toggleUnableToProceedModal: ActionCreator<
  IToggleUnableToProceedModal
> = (state: IErrorModalState) => ({
  type: TOGGLE_UNABLE_TO_PROCEED_MODAL,
  payload: state,
});

export const toggleCircularProgressIndicator: ActionCreator<
  IToggleCircularProgressIndicator
> = (state: boolean) => ({
  type: TOGGLE_CIRCULAR_PROGRESS_INDICATOR,
  payload: state,
});

export const toggleShortFormModal: ActionCreator<IToggleShortFormModal> = (
  state: boolean
) => ({
  type: TOGGLE_SHORT_FORM_MODAL,
  payload: state,
});

export const toggleShortFormThankYou: ActionCreator<
  IToggleShortFormThankYou
> = (state: boolean) => ({
  type: TOGGLE_SHORT_FORM_THANK_YOU,
  payload: state,
});

export const toggleSpinner: ActionCreator<IToggleSpinner> = (
  state: boolean
) => ({
  type: TOGGLE_SPINNER,
  payload: state,
});

export const toggleError: ActionCreator<IToggleError> = (state: boolean) => ({
  type: TOGGLE_ERROR_MODAL,
  payload: state,
});

export const toggleApplyPromocode: ActionCreator<IToggleApplyPromocode> = (
  state: boolean
) => ({
  type: TOGGLE_APPLY_PROMOCODE,
  payload: state,
});

export const hasPromocodeError: ActionCreator<IHasPromocodeError> = (
  state: boolean
) => ({
  type: HAS_PROMOCODE_ERROR,
  payload: state,
});
