let isLocalStorageAvailable = false;
let isSessionStorageAvailable = false;

(() => {
  isLocalStorageAvailable = checkLocalStorage();
  isSessionStorageAvailable = checkSessionStorage();
})();

function checkLocalStorage() {
  try {
    const x = "__local_storage_test__";
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

function checkSessionStorage() {
  try {
    const x = "__session_storage_test__";
    sessionStorage.setItem(x, x);
    sessionStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

export function cookieSet(
  name: string,
  value: string,
  minutes: number,
  base64Encoded: boolean = false,
  secure?: boolean
) {
  const now = new Date();
  now.setTime(now.getTime() + minutes * 60 * 1000);
  if (base64Encoded) value = btoa(value);
  document.cookie = `${name}=${value};expires=${now.toUTCString()};path=/${
    secure ? ";Secure" : ""
  }`;
}

export function cookieGet(name: string, base64Encoded: boolean = false) {
  const cookieName = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(cookieName) === 0) {
      let value = c.substring(cookieName.length, c.length);
      if (base64Encoded) value = atob(value);
      return value;
    }
  }
  return null;
}

export function cookieRemove(name: string) {
  const specialChars = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const invalidName = new RegExp(specialChars, "g");

  if (invalidName.test(name)) return;

  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
}

export function cookieRemoveRegEx(regex: RegExp) {
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
      const found = c.match(regex);
      if (found) cookieRemove(found[0]);
    }
  }
}

export function localSet(name: string, value: string) {
  if (isLocalStorageAvailable) localStorage.setItem(name, value);
}

export function localGet(name: string) {
  return isLocalStorageAvailable ? localStorage.getItem(name) : null;
}

export function localRemove(name: string) {
  if (isLocalStorageAvailable) localStorage.removeItem(name);
}

export function localClear() {
  if (isLocalStorageAvailable) localStorage.clear();
}

export function sessionSet(name: string, value: string) {
  if (isSessionStorageAvailable) sessionStorage.setItem(name, value);
}

export function sessionGet(name: string) {
  return isSessionStorageAvailable ? sessionStorage.getItem(name) : null;
}

export function sessionRemove(name: string) {
  if (isSessionStorageAvailable) sessionStorage.removeItem(name);
}

export function sessionClear() {
  if (isSessionStorageAvailable) sessionStorage.clear();
}
