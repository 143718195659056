import { BreakpointsOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mdModal: true;
    lgModal: true;
  }
}

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0, // mobile
    sm: 480, // mobile landscape
    md: 768, // tablet
    lg: 1024, // tablet landscape
    xl: 1280, // desktop
    mdModal: 640, // medium modal's max width
    lgModal: 768 // large modal's max width
  }
};

export default breakpoints;
