/**
 * Function to get short month name.
 * @param month Month returned by `Date.getMonth()`
 */
export function getShortMonthName(month: number) {
  if (month >= 0 && month < 12)
    return [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ][month];
  else return null;
}

/**
 * Function to compute how much time left. Returns in dd:hh:mm:ss format.
 * @param target Date to countdown to
 */
export function computeTimeRemaining(target: string) {
  const now = Date.now();
  const targetDate = new Date(target);
  if (!(targetDate instanceof Date)) return null;
  else if (isNaN(targetDate.getTime())) return null;
  else if (targetDate.getTime() - now < 0) return null;
  const d = targetDate.getTime() - now;
  let result = "";
  const days = Math.floor(d / (1000 * 60 * 60 * 24));
  if (days) result += days.toString().padStart(2, "0") + ":";
  const hours = Math.floor((d % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  if (days || hours) result += hours.toString().padStart(2, "0") + ":";
  const minutes = Math.floor((d % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((d % (1000 * 60)) / 1000);
  result += `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
  return result;
}

/**
 * Function to compute how much time left. Returns in dd hh mm ss format
 * If less than 1 day  returns in hh:mm:ss format
 * @param target Date to countdown to
 */
export function computeTimeRemainingV2(target: string): string | null {
  const now = Date.now();
  const targetDate = new Date(target);
  if (!(targetDate instanceof Date)) return null;
  else if (isNaN(targetDate.getTime())) return null;
  else if (targetDate.getTime() - now < 0) return null;
  const d = targetDate.getTime() - now;

  const days = Math.floor(d / (1000 * 60 * 60 * 24));
  const hours = Math.floor((d % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((d % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((d % (1000 * 60)) / 1000);

  let result = "";

  if (days) {
    result += days.toString().padStart(2, "0") + "d ";
  }
  if (hours) {
    result += hours.toString().padStart(2, "0") + "h ";
  }
  result += minutes.toString().padStart(2, "0") + "m ";
  result += seconds.toString().padStart(2, "0") + "s";

  return result;
}

/**
 * Function to compute time difference between two dates in milliseconds.
 * Returns `null` if arguments are invalid.
 * @param start Start date in ISO format
 * @param end End date in ISO format
 */
export function computeTimeDiffInMilli(start: string, end: string) {
  // 2019-10-21T07:17:00.599Z
  const startDate = new Date(start);
  const endDate = new Date(end);
  if (!(startDate instanceof Date) || !(endDate instanceof Date)) return null;
  else if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) return null;
  return Math.abs(startDate.getTime() - endDate.getTime());
}

/**
 * Function to determine whether `d1` is before `d2`. If `d2` is not provided, `d2` will be set to current time.
 * Returns `null` if arguments are invalid.
 * @param d1 Date 1 in ISO format
 * @param d2 (Optional) Date 2 in ISO format
 */
export function isBefore(d1: string, d2?: string) {
  const date1 = new Date(d1);
  const date2 = d2 ? new Date(d2) : new Date();
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) return null;
  else if (isNaN(date1.getTime()) || isNaN(date2.getTime())) return null;
  return date1.getTime() < date2.getTime();
}

/**
 * Function to determine whether `d1` is after `d2`. If `d2` is not provided, `d2` will be set to current time.
 * Returns `null` if arguments are invalid.
 * @param d1 Date 1 in ISO format
 * @param d2 (Optional) Date 2 in ISO format
 */
export function isAfter(d1: string, d2?: string) {
  const date1 = new Date(d1);
  const date2 = d2 ? new Date(d2) : new Date();
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) return null;
  else if (isNaN(date1.getTime()) || isNaN(date2.getTime())) return null;
  return date1.getTime() > date2.getTime();
}
